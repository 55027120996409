import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { EventContext } from '../App';
import '../assets/css/Events.css';
import successImage from '../app/webcomponents/assets/images/empty-states_illustration_success.svg';
import moment from 'moment'

const eventsText = (source, destination, eventType) => {
    switch (eventType) {
        case "Destination Creation":
            return [
                {
                    "actionType": 2,
                    "actionDescription": `Create a destination for ${source} using a custom name.`,
                    "showDetails": true,
                    "isDefault": true,
                    "label": "Create"
                },
                {
                    "actionType": 3,
                    "actionDescription": "Ignore the event. No association will be created.",
                    "showDetails": false,
                    "isDefault": false,
                    "label": "Ignore"
                }
            ];
        case "Destination Moved":
            return [
                {
                    "actionType": 1,
                    "actionDescription": `Move destination ${destination} according to the source.`,
                    "showDetails": false,
                    "isDefault": true,
                    "label": "Move"
                },
                {
                    "actionType": 3,
                    "actionDescription": `Ignore the event. Destination ${destination} will not be moved.`,
                    "showDetails": false,
                    "isDefault": false,
                    "label": "Ignore"
                }
            ];
        case "Destination Deleted":
            return [
                {
                    "actionType": 1,
                    "actionDescription": `Recreate destination for ${source}`,
                    "showDetails": false,
                    "isDefault": true,
                    "label": "Recreate"
                },
                {
                    "actionType": 2,
                    "actionDescription": `Recreate destination for ${source} using a custom name.`,
                    "showDetails": true,
                    "isDefault": false,
                    "label": "Recreate with custom name"
                },
                {
                    "actionType": 3,
                    "actionDescription": `Ignore the event. Destination will not be created.`,
                    "showDetails": false,
                    "isDefault": false,
                    "label": "Ignore"
                }
            ];
        case "Missing Association Found":
            return [
                {
                    "actionType": 1,
                    "actionDescription": `Associate the source ${source} site with the destination company.`,
                    "showDetails": false,
                    "isDefault": true,
                    "label": "Associate"
                },
                {
                    "actionType": 2,
                    "actionDescription": `Rename the existing ${destination} company with the prefix you add below. Then create a new destination company with the previous name and associate the source site with it.`,
                    "showDetails": true,
                    "isDefault": false,
                    "label": "Rename"
                },
                {
                    "actionType": 3,
                    "actionDescription": "Ignore the event. No association will be created.",
                    "showDetails": false,
                    "isDefault": false,
                    "label": "Ignore"
                }
            ];
        case "Source Deleted":
            return [
                {
                    "actionType": 1,
                    "actionDescription": `Delete destination ${destination}.`,
                    "showDetails": false,
                    "isDefault": true,
                    "label": "Delete"
                },
                {
                    "actionType": 2,
                    "actionDescription": `Keep destination ${destination}.`,
                    "showDetails": false,
                    "isDefault": false,
                    "label": "Keep"
                },
                {
                    "actionType": 3,
                    "actionDescription": `Ignore the event. Destination ${destination} will not be deleted.`,
                    "showDetails": false,
                    "isDefault": false,
                    "label": "Ignore"
                }
            ];
        case "Missing Prefix-based Association Found":
            return [
                {
                    "actionType": 1,
                    "actionDescription": `Associate the source ${source} site with the destination company.`,
                    "showDetails": false,
                    "isDefault": true,
                    "label": "Associate"
                },
                {
                    "actionType": 2,
                    "actionDescription": `Associate the source site with the destination company and rename latter to ${source}.`,
                    "showDetails": false,
                    "isDefault": false,
                    "label": "Rename"
                },
                {
                    "actionType": 3,
                    "actionDescription": `Ignore this event. No association will be created.`,
                    "showDetails": false,
                    "isDefault": false,
                    "label": "Ignore"
                }
            ];
    }
}

function formatDate(newDate) {
    const datetimeTitleFormat = 'MMM DD, YYYY hh:mm A';
    newDate = moment.utc(newDate).local().format(datetimeTitleFormat);
    return newDate;
}

const HandleEvents = props => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [taskStatus, setTaskStatus] = useState(-1);
    const taskResults = useContext(EventContext);

    const retrieveData = async () => {
        setLoading(true);
        const events = await axios({
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/api/events',
            withCredentials: true
        });

        setData(events.data);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }

    const displayAssociations = () => {
        if (data && data.length !== 0) {

            return data.map((event, index) => {
                let actionText = eventsText(event.rmmItemName, event.gravityZoneItemName, event.eventTypeName);
                event.newActionEventsText = actionText;

                return <tr key={index} id={index}>
                    <td className='col table-events'></td>
                    <td className='col table-events'>{event.entityTypeName ? event.entityTypeName : ''}</td>
                    <td className='col table-events'>{event.rmmItemName ? event.rmmItemName : ''}</td>
                    <td className='col table-events'>{event.gravityZoneItemName ? event.gravityZoneItemName : ''}</td>
                    <td className='col table-events'>{formatDate(event.createdDate)}</td>
                    <td className='col table-events eventsType'>{event.eventTypeName}</td>
                    <td className='col table-events'>
                        <div onClick={() => modalwithBody(event)}>
                            <gz-button type="outline" color="primary" buttonheight="small" buttonfontsize="medium">Resolve</gz-button>
                        </div>
                    </td>
                    <td className='col'></td>
                </tr>
            })
        }
    }

    const receiveResponseFromTask = () => {
        setTaskStatus(taskResults.taskStatusType);
    }

    const fixEvent = (e, eventModal, selectedOption, actionDetails) => {
        e.preventDefault();
        const value = eventModal.handlingActions.filter(el => el.actionType == selectedOption)[0];

        const requestBody = {
            'actionType': value.actionType,
            'actionDetails': actionDetails ? actionDetails : ''
        };

        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/api/events/' + eventModal.id,
            data: { 'eventHandleInfo': requestBody },
            withCredentials: true
        })
            .then(() => {
                props.setTaskStarted(true);
            })
            .catch((error) => {
                toastNotification(error.response.data, 'error')
            });

        closeAfterSomeEvent();
    }

    const toastNotification = (message, severity) => {
        const toastNotificationConfigs = {
            message: message,
            severity: severity,
            x: 'right',
            y: 'bottom',
            autoCloseDelayMs: 10000,
        };
        window.nirvana.dependencies.services.buildToastNotification(toastNotificationConfigs);
    }


    let dialog;
    const modalwithBody = (eventModal) => {
        const dialogBody = document.createElement('div');
        dialogBody.style.overflow = 'auto';

        let modalPrefix = `
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
        integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3" crossorigin="anonymous">
        <div class='px-5 py-3'>
        <div class='mb-5'>
        <gz-title fontsize='18' fontweight='500'>Details</gz-title>
        <div class='row my-3'>
            <div class='col-sm-3 table-text'><gz-text fontweight='500' fontsize='12'>Source</gz-text></div>
            <div class='col-sm-9 table-text'><gz-text fontweight='500' fontsize='12'>${eventModal.rmmItemName ? eventModal.rmmItemName : "-"}</gz-text></div>
        </div>
        <div class='row my-3'>
            <div class='col-sm-3 table-text'><gz-text fontweight='500' fontsize='12'>Destination</gz-text></div>
            <div class='col-sm-9 table-text'><gz-text fontweight='500' fontsize='12'>${eventModal.gravityZoneItemName ? eventModal.gravityZoneItemName : "-"}</gz-text></div>
        </div>
        </div>
        <gz-title fontsize='18' fontweight='500'>Actions</gz-title>
        <gz-radio-group id='actionGroup' gz-form-input="" errortooltiptext="" selectedoption=null>`;

        let elementwithInput;
        let selectedOption = 0;
        let actionDetails = '';
        let content = eventModal.newActionEventsText?.map((el) => {
            let radioPart = `<div class='py-3'>
                <gz-radio label="${el.label}" value=${el.actionType} id=${el.actionType}></gz-radio>
                <div class='ps-3'><gz-text fontsize='12'>${el.actionDescription}</gz-text></div>`;
            let lastPart = `</div>`;
            let optionalContent = '';
            if (el.showDetails) {
                optionalContent = `<div class='ps-3 pt-2'><gz-input id='action-details-input' placeholder='Destination Name' type='text' inputwidth='240' readonly value=${actionDetails}></gz-input></div>`;
                elementwithInput = el.actionType;
            }

            return radioPart + optionalContent + lastPart;
        }).join('');

        let finalBody = `</div>`;

        dialogBody.innerHTML = modalPrefix + content + finalBody;

        const footer = document.createElement('div');
        footer.innerHTML = `
            <gz-button id="confirmButton" color="primary">resolve</gz-button>
            <gz-button id="cancelButton" type="outline" color="secondary">cancel</gz-button>
        `;

        if (selectedOption === 0) {
            footer.querySelector('#confirmButton').setAttribute('disabled', true);
        }

        dialog = window.nirvana.dependencies.services.buildDialogElement(
            {
                headline: 'Resolve - ' + eventModal.eventTypeName,
                bodyContent: dialogBody,
                isOpen: true,
                footerContent: footer,
                onCloseHandler: closeAfterSomeEvent,
            },
            document.querySelector('#content')
        );
        footer.querySelector('#confirmButton').addEventListener('click', (e) => {
            dialog.dismiss();
            dialog.emitDialogEvent('confirm');
        });

        footer.querySelector('#cancelButton').addEventListener('click', () => {
            dialog.dismiss();
            dialog.emitDialogEvent('cancel');
            selectedOption = 0;
        });

        dialogBody.querySelector('#actionGroup').addEventListener('radioSelectionChanged', (e) => {
            if (footer.querySelector('#confirmButton').hasAttribute('disabled')) {
                footer.querySelector('#confirmButton').removeAttribute('disabled');
            }
            if (dialogBody.querySelector('#action-details-input') !== null) {
                if (elementwithInput == e.detail.selectedValue) {
                    dialogBody.querySelector('#action-details-input').removeAttribute('readonly');
                } else if (!dialogBody.querySelector('#action-details-input').hasAttribute('readonly')) {
                    dialogBody.querySelector('#action-details-input').setAttribute('readonly', true);
                    actionDetails = '';
                }
                dialogBody.querySelector('#action-details-input').addEventListener('change', e => actionDetails = e.target.value);
            }
            selectedOption = e.detail.selectedValue;
        });


        dialog.addEventListener('cancel', () => console.log('cancelled dialog'));
        dialog.addEventListener('confirm', (e) => fixEvent(e, eventModal, selectedOption, actionDetails));

    }

    const closeAfterSomeEvent = () => {
        dialog.dismiss();
    }

    useEffect(() => {
        retrieveData();
    }, []);

    useEffect(() => {
        receiveResponseFromTask();
    }, [taskResults])

    useEffect(() => {
        setTimeout(() => {
            if (taskResults.taskStatusType === 4 || taskResults.taskStatusType === 5) {
                retrieveData();
            }
        }, 1000);
    }, [taskStatus]);

    return (
        <div style={{ overflowY: 'scroll', height: '95vh' }}>
            <div className="mb-2 mx-0 mr-0">
                <div id='main-div-events'>
                    <div className='m-4'>
                        <gz-title fontsize="20" fontweight="500" style={{ opacity: '0.7' }}>Events</gz-title>
                    </div>
                    {loading ?
                        <div className='center-div'>
                            <gz-progress-spinner indeterminate="" spinnerwidth="medium"></gz-progress-spinner>
                        </div> :
                        <div>
                            {data && data.length !== 0 ?
                                <table className='table table-text align-middle'>
                                    <thead className='sticky-top' style={{ backgroundColor: 'white' }}>
                                        <tr id='table-head'>
                                            <th scope='col' className='table-events'></th>
                                            <th scope='col' className='table-events'>Type</th>
                                            <th scope='col' className='table-events'>Source</th>
                                            <th scope='col' className='table-events'>Destination</th>
                                            <th scope='col' className='table-events'>Generated</th>
                                            <th scope='col' className='table-events'>Event Type</th>
                                            <th scope='col' className='table-events'>Action</th>
                                            <th scope='col' className='table-events'></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {displayAssociations()}
                                    </tbody>
                                </table> :
                                <div className='center-div'>
                                    <img src={successImage} />
                                    <gz-title fontweight="500" fontsize="20" style={{ marginBottom: '10px' }}>Everything is clean.</gz-title>
                                    <gz-text fontsize="13" style={{ marginBottom: '5px' }} fontweight="400">No events to be handled.</gz-text>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default HandleEvents;
