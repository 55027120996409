import { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEventListener } from '../utils/customHooks';
import authContext from '../utils/authContext';
import { TENANT_STATUSES_MESSAGE } from '../utils/constants.js';
import axios from 'axios';
import '../assets/css/Login.css';

function LoginPage() {
    const [serverAddress, setServerAddress] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [apiSecretKey, setApiSecretKey] = useState('');
    const [ssoAlias, setSsoAlias] = useState('');
    const [isLoginSuccessfulWithValidCredentials, setIsLoginSuccessfulWithValidCredentials] = useState(null);
    const [isNewUser, setIsNewUser] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSsoLogin, setIsSsoLogin] = useState(false);
    const [allInputsEnabled, setAllInputsEnabled] = useState(true);

    const { setAuthenticated } = useContext(authContext);

    const navigate = useNavigate();

    const ssoAliasInputRef = useRef();
    const serverAddressInputRef = useRef();
    const apiKeyInputRef = useRef();
    const apiSecretKeyInputRef = useRef();
    const loginBtnRef = useRef(null);
    const signInWithGzRef = useRef(null);

    const invalidErrors = [
        'invalid ssoAlias',
        'invalid serverAddress',
        'invalid apiKey',
        'invalid apiSecretKey'
    ]

    const returnErrorMessages = {
        'ERR0': 'Signed in successfully',
        'ERR1': 'Signed in successfully, but invalid credentials',
        'ERR2': 'Tenant not registered',
        'ERR3': 'The alias is invalid, disabled, or single sign-on is not configured. To configure SSO, log in using your GravityZone credentials or API key.',
        'ERR4': 'Your account has restricted access to companies in GravityZone. Contact your administrator.'
    };

    const redirectErrorMessage = {
        'isCustomer': 'You do not have the necessary rights to log in to Datto RMM. Contact your GravityZone administrator.',
        'gzDisabled': 'GravityZone IdP is disabled. Log in using your SSO alias or API details.',
        'error': 'The server encountered an unexpected error.',
        'companyTargetsError': 'Your account has restricted access to companies in GravityZone. Contact your administrator.'
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const status = urlParams.get('status');

        const errorMessage = redirectErrorMessage[status];
        if (errorMessage !== undefined) {
            toastNotification(errorMessage, 'error');
            navigate('/login');
        }
    }, []);

    const handleInputChange = (e, setValue, isSsoValue) => {
        setValue(e.target.value);
        setIsSsoLogin(isSsoValue);
        setAllInputsEnabled(false);
    };

    useEffect(() => {
        if (!ssoAlias && !serverAddress && !apiKey && !apiSecretKey) {
            setAllInputsEnabled(true);
        }
    });

    useEffect(() => {
        if (serverAddress) {
            serverAddressInputRef.current.markAsValid();
        }
        if (apiKey) {
            apiKeyInputRef.current.markAsValid();
        }
        if (apiSecretKey) {
            apiSecretKeyInputRef.current.markAsValid();
        }
    }, [serverAddress, apiKey, apiSecretKey]);

    useEffect(() => {
        ssoAliasInputRef.current.markAsValid();
        serverAddressInputRef.current.markAsValid();
        apiKeyInputRef.current.markAsValid();
        apiSecretKeyInputRef.current.markAsValid();
    }, [allInputsEnabled]);

    const validateLogin = () => {
        let temp = {};

        const dattoUrlSuffix = 'centrastage\.net';

        if (isSsoLogin) {
            temp.ssoAlias = ssoAlias.length > 0 ? '' : invalidErrors[0];

            if (temp.ssoAlias !== '') {
                ssoAliasInputRef.current.markAsInvalid();
            } else {
                ssoAliasInputRef.current.markAsValid();
            }
        }
        else {
            temp.serverAddress = RegExp('((https):\/\/)[-a-zA-Z0-9@:%._\+~#=]{1,256}\.' + dattoUrlSuffix + '$').test(serverAddress) ? '' : invalidErrors[1];
            temp.apiKey = apiKey.length > 0 ? '' : invalidErrors[2];
            temp.apiSecretKey = apiSecretKey.length > 0 ? '' : invalidErrors[3];

            if (temp.serverAddress !== '') {
                serverAddressInputRef.current.markAsInvalid();
            } else {
                serverAddressInputRef.current.markAsValid();
            }

            if (temp.apiKey !== '') {
                apiKeyInputRef.current.markAsInvalid();
            } else {
                apiKeyInputRef.current.markAsValid();
            }

            if (temp.apiSecretKey !== '') {
                apiSecretKeyInputRef.current.markAsInvalid();
            } else {
                apiSecretKeyInputRef.current.markAsValid();
            }
        }

        return Object.values(temp).every(x => x === '');
    }

    const toastNotification = (message) => {
        const toastNotificationConfigs = {
            message: message,
            severity: 'error',
            x: 'right',
            y: 'bottom',
            autoCloseDelayMs: 10000,
        };

        window.nirvana.dependencies.services.buildToastNotification(toastNotificationConfigs);
    }

    const clearLoginInputs = (e) => {
        apiKeyInputRef.current.clearInputValue(e);
        apiSecretKeyInputRef.current.clearInputValue(e);
        serverAddressInputRef.current.clearInputValue(e);
        ssoAliasInputRef.current.clearInputValue(e);
    }

    const submitLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (!validateLogin()) {
            setIsLoading(false);
            return;
        }

        if (isSsoLogin) {
            try {
                let response = await axios({
                    method: 'get',
                    url: process.env.REACT_APP_API_URL + '/api/user/ssoLogin',
                    params: {
                        ssoAlias: ssoAlias
                    },
                    withCredentials: true
                });
                
                setIsLoading(false);
                clearLoginInputs(e);
                window.location.href = response.data.url;
                
            } catch (error) {
                if (error.response) {
                    if (error.response.status === 404) {
                        if (error.response.data === Object.keys(returnErrorMessages)[2]){
                            setIsNewUser(true);
                        } else if(error.response.data === TENANT_STATUSES_MESSAGE.gzCompanyTargetsError) {
                            ssoAliasInputRef.current.markAsInvalid();
                            toastNotification(returnErrorMessages.ERR4);

                        }
                    }
                    else {
                        ssoAliasInputRef.current.markAsInvalid();
                        toastNotification(returnErrorMessages.ERR3);
                    }
                }
                setIsLoading(false);
            }

            return;
        }

        try {
            let response = await axios({
                method: 'post',
                url: process.env.REACT_APP_API_URL + '/api/user/signIn',
                data: {
                    rmmApiKey: apiKey,
                    rmmApiSecret: apiSecretKey,
                    rmmServerAddress: serverAddress
                },
                withCredentials: true
            });

            if (response.data === Object.keys(returnErrorMessages)[1]) {
                setIsLoginSuccessfulWithValidCredentials(false);
            } else if (response.data === Object.keys(returnErrorMessages)[0]) {
                setIsLoginSuccessfulWithValidCredentials(true);
            }
            setIsLoading(false);
        } catch (error) {
            if (error.response) {
                switch (error.response.status) {
                    case 404:
                        if (error.response.data === Object.keys(returnErrorMessages)[2]) {
                            setIsNewUser(true);
                            break;
                        }
                    default:
                        serverAddressInputRef.current.markAsInvalid();
                        apiKeyInputRef.current.markAsInvalid();
                        apiSecretKeyInputRef.current.markAsInvalid();
                        toastNotification(error.response.data);
                        break;
                }
            }
            setIsLoading(false);
        }
    }

    const signInWithGz = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            let response = await axios({
                method: 'get',
                url: process.env.REACT_APP_API_URL + '/api/user/gzSsoLogin',
                withCredentials: true
            });
            
            setIsLoading(false);
            clearLoginInputs(e);
            window.location.href = response.data.url;
        } catch (error) {
            toastNotification(error.response.data, 'error');
            setIsLoading(false);
        }
    }

    useEffect(() => {
        apiKeyInputRef.current.addEventListener('change', e => handleInputChange(e, setApiKey, false));
        apiSecretKeyInputRef.current.addEventListener('change', e => handleInputChange(e, setApiSecretKey, false));
        serverAddressInputRef.current.addEventListener('change', e => handleInputChange(e, setServerAddress, false));
        ssoAliasInputRef.current.addEventListener('change', e => handleInputChange(e, setSsoAlias, true));
        signInWithGzRef.current.addEventListener('click', e => signInWithGz(e));
    }, []);

    useEffect(() => {
        if (isLoginSuccessfulWithValidCredentials === undefined || isLoginSuccessfulWithValidCredentials === null) {
            return;
        }
        if (isLoginSuccessfulWithValidCredentials === true) {
            navigate('/companies');
            setAuthenticated(true);
        } else {
            navigate('/integrations');
            setAuthenticated(true);
        }
    }, [isLoginSuccessfulWithValidCredentials, navigate]);

    useEffect(() => {
        if (!isNewUser) {
            return;
        }
        if (isNewUser) {
            navigate('/integrations');
            setAuthenticated(false);
        }
    }, [isNewUser, navigate]);

    useEventListener('click', submitLogin, loginBtnRef.current);

    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-sm-2 left-side d-none d-sm-block' />
                <div className='col-sm-3' />
                <div className='col-sm-7 mt-5'>
                    <div className='row pt-5'>
                        <div className='col-sm-7'>
                            <span className='icon ds-icon-bitdefender_logo_bw_web' style={{ fontSize: '28px' }}></span>
                            <gz-title fontWeight='500' fontSize='20'>Login to Datto RMM APP</gz-title>

                            <div className='mt-5 mb-3'>
                                <gz-button type='outline' color='primary' buttonheight='medium' buttonfontsize='medium'
                                    ref={signInWithGzRef}
                                    id='sign-in-btn'>
                                    Sign in with GravityZone
                                </gz-button>
                            </div>
                            <div className='divider'>OR</div>
                            <div className='mb-3'>
                                <gz-input
                                    gz-form-input
                                    {...(allInputsEnabled ? {} : (!isSsoLogin ? { disabled: true } : {}))}
                                    errortooltiptext='Invalid SSO alias.'
                                    inputwidth='240'
                                    maxchars='100'
                                    label='SSO Alias'
                                    type='text'
                                    value={ssoAlias}
                                    id='ssoAlias-input'
                                    ref={ssoAliasInputRef}
                                >
                                </gz-input>
                            </div>
                            <div className='divider'>OR</div>
                            <div className='mb-3' >
                                <gz-input
                                    gz-form-input
                                    {...(allInputsEnabled ? {} : (isSsoLogin ? { disabled: true } : {}))}
                                    errortooltiptext='Invalid API URL.'
                                    inputwidth='240'
                                    maxchars='100'
                                    label='API URL'
                                    type='text'
                                    value={serverAddress}
                                    id='serverAddress-input'
                                    ref={serverAddressInputRef}
                                >
                                </gz-input>
                            </div>

                            <div className='mb-3' >
                                <gz-input
                                    gz-form-input
                                    {...(allInputsEnabled ? {} : (isSsoLogin ? { disabled: true } : {}))}
                                    errortooltiptext='Invalid API key.'
                                    inputwidth='240'
                                    maxchars='100'
                                    label='API Key'
                                    type='text'
                                    value={apiKey}
                                    id='apiKey-input'
                                    ref={apiKeyInputRef}
                                >
                                </gz-input>
                            </div>
                            <div className='mb-3' >
                                <gz-input
                                    gz-form-input
                                    {...(allInputsEnabled ? {} : (isSsoLogin ? { disabled: true } : {}))}
                                    errortooltiptext='Invalid API secret key.'
                                    inputwidth='240'
                                    maxchars='100'
                                    label='API Secret Key'
                                    type='password'
                                    value={apiSecretKey}
                                    id='apiSecretKey-input'
                                    ref={apiSecretKeyInputRef}
                                >
                                </gz-input>
                            </div>

                            <gz-button type='full' color='primary' buttonheight='medium' buttonfontsize='medium'
                                ref={loginBtnRef}
                                id='login-btn' {...(isLoading === true ? { disabled: true } : {})}
                            >
                                Login
                            </gz-button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default LoginPage;