import { useEffect, useState, useContext, useRef } from 'react';
import { useEventListener } from '../utils/customHooks';
import { useNavigate } from 'react-router-dom';
import authContext from '../utils/authContext';
import axios from 'axios';
import '../assets/css/SettingsTab.css';
import { TENANT_STATUSES_MESSAGE } from '../utils/constants.js';

const initialState = {
    gzApiKey: '',
    gzServerAddress: '',
    rmmApiKey: '',
    rmmApiSecret: '',
    serverAddress: ''
};

const dattoUrlSuffix = 'centrastage\.net';

const tenantStatuses = {
    'newTenant': 'New Tenant',
    'newTenantButInvalid': 'New Tenant But Invalid',
    'resetTenant': 'Reset Tenant',
    'invalidTenant': 'Tenant With Invalid Credentials'
}

const statuses = {
    'success': 'success',
    'gz': 'gz'
}

function SettingsTab() {
    const [gzApiKey, setGzApiKey] = useState('');
    const [initialGzApiKey, setInitialGzApiKey] = useState('');
    const [gzServerAddress, setGzServerAddress] = useState('');
    const [initialGzServerAddress, setInitialGzServerAddress] = useState('');
    const [serverAddress, setServerAddress] = useState('');
    const [rmmApiKey, setRmmApiKey] = useState('');
    const [rmmApiSecret, setRmmApiSecret] = useState('');
    const [isGzAuthorizationEnabled, setIsGzAuthorizationEnabled] = useState(false);
    const [isGzAuthenticationEnabled, setIsGzAuthenticationEnabled] = useState(false);
    const [changedValues, setChangedValues] = useState({
        gzApiKey: null,
        gzServerAddress: null,
        rmmApiKey: null,
        rmmApiSecret: null,
        serverAddress: null
    });

    const [errors, setErrors] = useState(initialState);
    const [tenantStatus, setTenantStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false); //button loading
    const [loading, setLoading] = useState(true); //screen loading
    const [disabledButton, setDisabledButton] = useState(false);
    const [canApiURLBeChanged, setCanApiURLBeChanged] = useState(false);
    const [isInitialAuthorizationEnabled, setIsInitialAuthorizationEnabled] = useState(false);
    const [hasGzAccessToken, setHasGzAccessToken] = useState(false);
    const [gzApiKeyInputType, setGzApiKeyInputType] = useState("password");

    const { authenticated, setAuthenticated } = useContext(authContext);

    const gzAuthorizationRef = useRef(null);
    const gzCredentialsRef = useRef(null);
    const gzApiKeyRef = useRef(null);
    const gzApiKeyFieldRef = useRef(null);
    const gzServerAddressRef = useRef(null);
    const gzServerAddressFieldRef = useRef(null);
    const rmmApiKeyRef = useRef(null);
    const rmmApiSecretRef = useRef(null);
    const serverAddressRef = useRef(null);
    const saveSettingsButtonRef = useRef(null);

    const navigate = useNavigate();

    const redirectMessage = {
        'isCustomer': 'You do not have the necessary rights to log in to Datto RMM. Contact your GravityZone administrator.',
        'differentCompany': 'Changes not saved. Check your GravityZone account.',
        'error': 'The server encountered an unexpected error.',
        'success': 'Changes successfully saved.',
        'invalidSubscription': 'Changes were not saved. GravityZone MSP license is required.',
        'companyTargetsError': 'Your account has restricted access to companies in GravityZone. Contact your administrator.'
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const status = urlParams.get('status');

        const message = redirectMessage[status];
        if (message !== undefined) {
            if (status === statuses.success) {
                toastNotification(message, 'success');
            }
            else {
                if (message === redirectMessage['differentCompany']) {
                    handleDifferentCompanyError();
                }
                toastNotification(message, 'error');
            }
            navigate('/integrations');
        }
    }, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const status = urlParams.get('login');

        if (status === statuses.gz) {
            setHasGzAccessToken(true);
            navigate('/integrations');
        }
    }, []);

    const getCredentials = async () => {
        setLoading(true);
        try {
            const response = await axios({
                method: 'get',
                url: process.env.REACT_APP_API_URL + '/api/tenant/settings',
                withCredentials: true
            });
            setGzApiKeyInputType("text");
            setGzApiKey(response.data.gzApiKeyMasked);
            setInitialGzApiKey(response.data.gzApiKeyMasked);
            setGzServerAddress(response.data.gzServerAddress);
            setInitialGzServerAddress(response.data.gzServerAddress);
            setRmmApiKey(response.data.rmmApiKey);
            setServerAddress(response.data.serverAddress);
            setIsGzAuthenticationEnabled(response.data.isGzAuthenticationEnabled)
            setIsGzAuthorizationEnabled(response.data.isGzAuthorizationEnabled);
            setIsInitialAuthorizationEnabled(response.data.isGzAuthorizationEnabled);
            setHasGzAccessToken(response.data.isGzAuthorizationEnabled);

        } catch (error) {
            console.log(error)
            setErrors({ receiveDataError: error.response.data });
        } finally {
            setLoading(false);
        }
    }

    const handleServerAddressChange = (e) => {
        setServerAddress(e.target.value);
        let items = changedValues;
        items.serverAddress = e.target.value;
        setChangedValues(items);
    }

    const handleGzApiKeyChange = (e) => {
        setGzApiKeyInputType("password");
        setGzApiKey(e.target.value);
        let items = changedValues;
        items.gzApiKey = e.target.value;
        setChangedValues(items);
    }

    const handleGzServerAddressChange = (e) => {
        setGzServerAddress(e.target.value);
        let items = changedValues;
        items.gzServerAddress = e.target.value;
        setChangedValues(items);
    }

    const handleRmmApiKeyChange = (e) => {
        setRmmApiKey(e.target.value);
        let items = changedValues;
        items.rmmApiKey = e.target.value;
        setChangedValues(items);
    }

    const handleRmmApiSecretChange = (e) => {
        setRmmApiSecret(e.target.value);
        let items = changedValues;
        items.rmmApiSecret = e.target.value;
        setChangedValues(items);
    }

    const toastNotification = (message, severity) => {
        const toastNotificationConfigs = {
            message: message,
            severity: severity,
            x: 'right',
            y: 'bottom',
            autoCloseDelayMs: 10000,
        };

        window.nirvana.dependencies.services.buildToastNotification(toastNotificationConfigs);
    }

    const validateForm = () => {
        let temp = {};

        temp.gzApiKey = '';
        if (isGzAuthorizationEnabled === false) {
            if (gzApiKey === '' && changedValues.gzApiKey === null) {
                temp.gzApiKey = 'GravityZone API Key';
            } else if (changedValues.gzApiKey !== null) {
                temp.gzApiKey = changedValues.gzApiKey.length !== 0 ? '' : 'GravityZone API Key';
            }
        }

        temp.gzServerAddress = '';
        if (isGzAuthorizationEnabled === false) {
            if (gzServerAddress === '' && changedValues.gzServerAddress === null) {
                temp.gzServerAddress = 'GravityZone Access URL';
            } else if (changedValues.gzServerAddress !== null) {
                temp.gzServerAddress = /((https):\/\/)[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/.test(changedValues.gzServerAddress) ? '' : 'GravityZone Access URL';
            }
        }

        if (changedValues.rmmApiKey !== null) {
            temp.rmmApiKey = changedValues.rmmApiKey.length !== 0 ? '' : 'Datto RMM API Key';
        }
        if (changedValues.rmmApiSecret !== null && changedValues.rmmApiSecret !== rmmApiSecret) {
            temp.rmmApiSecret = changedValues.rmmApiSecret.length !== 0 ? '' : 'Datto RMM API Secret Key';
        }
        if (changedValues.serverAddress !== null) {
            temp.serverAddress = RegExp('((https):\/\/)[-a-zA-Z0-9@:%._\+~#=]{1,256}\.' + dattoUrlSuffix + '$').test(changedValues.serverAddress) ? '' : 'Datto RMM API URL';
        }

        inputError(temp);

        return Object.values(temp).every(x => x === '');
    }

    const validateRegister = () => {
        let temp = {};
        if (isGzAuthorizationEnabled === false) {
            if (changedValues.gzApiKey === null) {
                temp.gzApiKey = 'GravityZone API Key';
            } else {
                temp.gzApiKey = changedValues.gzApiKey.length !== 0 ? '' : 'GravityZone API Key';
            }
        } else {
            temp.gzApiKey = '';
        }

        if (isGzAuthorizationEnabled === false) {
            if (changedValues.gzServerAddress !== null) {
                temp.gzServerAddress = /((https):\/\/)[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/.test(changedValues.gzServerAddress) ? '' : 'GravityZone Access URL';
            } else {
                temp.gzServerAddress = 'GravityZone Access URL';
            }
        } else {
            temp.gzApiKey = '';
        }

        if (changedValues.rmmApiKey !== null) {
            temp.rmmApiKey = changedValues.rmmApiKey.length !== 0 ? '' : 'Datto RMM API Key';
        } else {
            temp.rmmApiKey = 'Datto RMM API Key';
        }
        if (changedValues.rmmApiSecret !== null) {
            temp.rmmApiSecret = changedValues.rmmApiSecret.length !== 0 ? '' : 'Datto RMM API Secret Key';
        } else {
            temp.rmmApiSecret = 'Datto RMM API Secret Key';
        }
        if (changedValues.serverAddress !== null) {
            temp.serverAddress = RegExp('((https):\/\/)[-a-zA-Z0-9@:%._\+~#=]{1,256}\.' + dattoUrlSuffix + '$').test(changedValues.serverAddress) ? '' : 'Datto RMM API URL';
        } else {
            temp.serverAddress = 'Datto RMM API URL';
        }

        inputError(temp);

        return Object.values(temp).every(x => x === '');
    }

    const inputError = (temp) => {
        if (temp.hasOwnProperty('gzApiKey')) {
            if (temp.gzApiKey !== '') {
                gzApiKeyRef.current.markAsInvalid();
                setIsLoading(false);
            } else {
                gzApiKeyRef.current.markAsValid();
            }
        }
        if (temp.hasOwnProperty('gzServerAddress')) {
            if (temp.gzServerAddress !== '') {
                gzServerAddressRef.current.markAsInvalid();
                setIsLoading(false);
            } else {
                gzServerAddressRef.current.markAsValid();
            }
        }
        if (temp.hasOwnProperty('rmmApiKey')) {
            if (temp.rmmApiKey !== '') {
                rmmApiKeyRef.current.markAsInvalid();
                setIsLoading(false);
            } else {
                rmmApiKeyRef.current.markAsValid();
            }
        }
        if (temp.hasOwnProperty('rmmApiSecret')) {
            if (temp.rmmApiSecret !== '') {
                rmmApiSecretRef.current.markAsInvalid();
                setIsLoading(false);
            } else {
                rmmApiSecretRef.current.markAsValid();
            }
        }
        if (temp.hasOwnProperty('serverAddress')) {
            if (temp.serverAddress !== '') {
                serverAddressRef.current.markAsInvalid();
                setIsLoading(false);
            } else {
                serverAddressRef.current.markAsValid();
            }
        }
    }

    const handleFirstTimeUse = async (tenantStatus) => {
        const dialogBody = document.createElement('div');
        dialogBody.innerHTML =
            `<p>Since this is your first time here, we would like to help you set up your environment properly.</p>
            <br/>
            <p>Make sure to fill in the required information on the <b>Integration</b> and <b>Default Packages</b> pages.</p>`;

        const invalidDialogBody = document.createElement('div');
        invalidDialogBody.innerHTML =
            `<p>Since your last time here, your credentials have become invalid or do not exist anymore.</p>
        <br/>
        <p>To use the Datto integration, add new credentials in the missing fields.</p>`;


        const footer = document.createElement('div');
        footer.innerHTML = `<gz-button id="confirmButton">OK</gz-button>`;

        const confirmButton = footer.querySelector('#confirmButton');

        const dialogConfigs = {
            headline: tenantStatus === tenantStatuses.newTenant ? "Welcome!" : 'Invalid integration credentials!',
            bodyContent: tenantStatus === tenantStatuses.newTenant ? dialogBody : invalidDialogBody,
            footerContent: footer,
            isOpen: true,
        };

        const dialog = window.nirvana.dependencies.services.buildDialogElement(dialogConfigs);

        if (confirmButton) {
            confirmButton.addEventListener('click', () => { dialog.dismiss() })
        }
    }

    const handleGzUserToken = async () => {
        const dialogBody = document.createElement('div');
        dialogBody.innerHTML =
            `<p>To use the access token option, you must sign in with your GravityZone account.</p>`;

        const footer = document.createElement('div');
        footer.innerHTML =
            `<gz-button id="gzIdpButton">SIGN IN WITH GRAVITYZONE</gz-button>
             <gz-button id="cancelButton" type="outline" color="secondary">cancel</gz-button>`;

        const gzIdpButton = footer.querySelector('#gzIdpButton');
        const cancelButton = footer.querySelector('#cancelButton');

        const dialogConfigs = {
            headline: 'Change sign-in method?',
            bodyContent: dialogBody,
            footerContent: footer,
            isOpen: true,
        };

        const dialog = window.nirvana.dependencies.services.buildDialogElement(dialogConfigs);

        if (gzIdpButton) {
            gzIdpButton.addEventListener('click', async () => {
                setIsLoading(true);
                let hasError = false;

                if (changedValues.rmmApiKey !== null || changedValues.rmmApiSecret !== null || changedValues.serverAddress !== null) {
                    await axios({
                        method: 'post',
                        url: process.env.REACT_APP_API_URL + '/api/tenant/settings/rmm',
                        data: {
                            rmmApiKey: changedValues.rmmApiKey ?? rmmApiKey,
                            rmmApiSecret: changedValues.rmmApiSecret ?? rmmApiSecret,
                            rmmServerAddress: changedValues.serverAddress ?? serverAddress
                        },
                        withCredentials: true
                    })
                        .catch((error) => {
                            toastNotification(error.response.data, 'error');
                            setIsLoading(false);
                            hasError = true;
                        });
                }

                if (!hasError) {
                    let response = await axios({
                        method: 'get',
                        url: process.env.REACT_APP_API_URL + '/api/user/gzSsoLogin',
                        withCredentials: true
                    });

                    window.location.href = response.data.url;
                    setIsLoading(false);
                }
            })
        }

        if (cancelButton) {
            cancelButton.addEventListener('click', () => { dialog.dismiss() })
        }
    }

    const handleDifferentCompanyError = () => {
        const dialogBody = document.createElement('div');
        dialogBody.innerHTML =
            `<p>The user token does not match the GravityZone company set for integration. Please generate a new token with the correct GravityZone partner account.</p>
            <br/>
            <p><b>Important</b></p>
            <p>Log out of your current GravityZone session before generating the new token.</p>
            `;

        const footer = document.createElement('div');
        footer.innerHTML =
            `<gz-button id="okButton">OK</gz-button>`

        const okButton = footer.querySelector('#okButton');

        const dialogConfigs = {
            headline: 'Error',
            bodyContent: dialogBody,
            footerContent: footer,
            isOpen: true,
        };

        const dialog = window.nirvana.dependencies.services.buildDialogElement(dialogConfigs);

        if (okButton) {
            okButton.addEventListener('click', () => { dialog.dismiss() })
        }
    }

    const handleConfictCompanyError = () => {
        const dialogBody = document.createElement('div');
        dialogBody.innerHTML =
            `<p>The GravityZone company is already in use by another Bitdefender integration.</p>
            <br/>
            <p>Please contact Bitdefender Enterprise Support for assistance.</p>
            `;

        const footer = document.createElement('div');
        footer.innerHTML =
            `<gz-button id="okButton">OK</gz-button>`

        const okButton = footer.querySelector('#okButton');

        const dialogConfigs = {
            headline: 'Unable to continue configuration',
            bodyContent: dialogBody,
            footerContent: footer,
            isOpen: true,
        };

        const dialog = window.nirvana.dependencies.services.buildDialogElement(dialogConfigs);

        if (okButton) {
            okButton.addEventListener('click', () => { 
                dialog.dismiss(); 
                setTenantStatus(tenantStatuses.newTenant);
            })
        }
    }

    const resetChanges = () => {    
        setChangedValues({
            gzApiKey: null,
            gzServerAddress: null,
            rmmApiKey: null,
            rmmApiSecret: null,
            serverAddress: null
        });
    }

    const handleGzCredentials = () => {
        setCanApiURLBeChanged(true);
        setIsGzAuthorizationEnabled(true);
        setIsGzAuthenticationEnabled(true);
    }

    const manageConflictCompany = () => {
        setTenantStatus(tenantStatuses.newTenantButInvalid);
        handleGzCredentials();
        gzApiKeyRef.current.clearInputValue(new Event('clear'));
        gzServerAddressRef.current.clearInputValue(new Event('clear'));
        rmmApiKeyRef.current.clearInputValue(new Event('clear'));
        rmmApiSecretRef.current.clearInputValue(new Event('clear'));
        serverAddressRef.current.clearInputValue(new Event('clear'));
        handleConfictCompanyError();
    }

    const saveData = (e) => {
        e.preventDefault();
        if (isGzAuthorizationEnabled === true && !hasGzAccessToken) {
            if (tenantStatus === tenantStatuses.newTenant || tenantStatus === tenantStatuses.resetTenant) {
                if (validateRegister()) handleGzUserToken();
            }
            else {
                if (validateForm()) handleGzUserToken();
            }
            return;
        }

        setIsLoading(true);

        if (!authenticated && tenantStatus === tenantStatuses.newTenant) {
            if (validateRegister()) {
                axios({
                    method: 'post',
                    url: process.env.REACT_APP_API_URL + '/api/tenant',
                    data: {
                        ...changedValues,
                        isGzAuthorizationEnabled: isGzAuthorizationEnabled,
                        isGzAuthenticationEnabled: isGzAuthorizationEnabled
                    },
                    withCredentials: true
                })
                    .then(() => {
                        toastNotification('Credentials successfully saved.', 'success');
                        setIsLoading(false);
                        navigate('/defaultpackage');
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.status === 500) {
                                toastNotification('The server encountered an unexpected error.', 'error');
                            } else if(error.response.status === 409) {
                                setTenantStatus(tenantStatuses.newTenantButInvalid);
                                resetChanges();
                                manageConflictCompany();
                            } else {
                                toastNotification(error.response.data, 'error');
                            }
                        }
                        setIsLoading(false);
                    });
            }
        } else if (!authenticated && tenantStatus === tenantStatuses.resetTenant) {
            if (validateRegister()) {
                axios({
                    method: 'put',
                    url: process.env.REACT_APP_API_URL + '/api/tenant',
                    data: changedValues,
                    withCredentials: true
                })
                    .then(() => {
                        toastNotification('Changes successfully saved.', 'success');
                        setIsLoading(false);
                        navigate('/defaultpackage');
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.status === 500) {
                                toastNotification('The server encountered an unexpected error.', 'error');
                            } else if(error.response.status === 409) {
                                setTenantStatus(tenantStatuses.newTenantButInvalid);
                                resetChanges();
                                manageConflictCompany();
                            } else {
                                toastNotification(error.response.data, 'error');
                            }
                        }
                        setIsLoading(false);
                    });
            } else {
                setIsLoading(false);
            }
        } else {
            if (validateForm()) {
                patchTenantSettings();
            } else {
                setIsLoading(false);
            }
        }
    }

    const patchTenantSettings = () => {
        axios({
            method: 'patch',
            url: process.env.REACT_APP_API_URL + '/api/tenant/settings',
            data: {
                ...changedValues,
                isGzAuthenticationEnabled: isGzAuthenticationEnabled,
                isGzAuthorizationEnabled: isGzAuthorizationEnabled
            },
            withCredentials: true
        })
            .then(() => {
                toastNotification('Changes successfully saved.', 'success');
                setIsLoading(false);
                if (tenantStatus === tenantStatuses.invalidTenant) {
                    setAuthenticated(true);
                    navigate('/companies');
                }
            })
            .catch((error) => {
                toastNotification(error.response.data, 'error');
                setIsLoading(false);
            });
        setIsInitialAuthorizationEnabled(isGzAuthorizationEnabled);
    }

    useEffect(() => {
        if (isLoading === true && Object.values(changedValues).every(x => x === null || x === '') === true) {
            setDisabledButton(true);
        } else if (isLoading === false && Object.values(changedValues).every(x => x === null || x === '') === true) {
            if (isInitialAuthorizationEnabled === isGzAuthorizationEnabled && tenantStatus !== tenantStatuses.newTenant) {
                setDisabledButton(true);
            } else if(tenantStatus === tenantStatuses.newTenantButInvalid) {
                setDisabledButton(true);
            } else {
                setDisabledButton(false);
            }
        } else if (isLoading === true && Object.values(changedValues).every(x => x === null || x === '') === false) {
            if (isInitialAuthorizationEnabled === isGzAuthorizationEnabled && tenantStatus !== tenantStatuses.newTenant) {
                setDisabledButton(true);
            } else {
                setDisabledButton(false);
            }
        } else if (isLoading === false && Object.values(changedValues).every(x => x === null || x === '') === false) {
            setDisabledButton(false);
        }
    }, [isLoading, Object.values(changedValues)]);

    useEffect(() => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/api/tenant',
            withCredentials: true
        })
            .then(() => {
                getCredentials();
                resetChanges();
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 404 && (error.response.data === TENANT_STATUSES_MESSAGE.notRegistered || error.response.data === TENANT_STATUSES_MESSAGE.firstTimeUseToken)) {
                        setLoading(false);
                        setTenantStatus(tenantStatuses.newTenant);
                        handleGzCredentials();
                        handleFirstTimeUse(tenantStatuses.newTenant);
                    } else if (error.response.status === 404 && (error.response.data === TENANT_STATUSES_MESSAGE.notFound || error.response.data === TENANT_STATUSES_MESSAGE.resetTenant)) {
                        setTenantStatus(tenantStatuses.resetTenant);
                        setLoading(false);
                        handleGzCredentials();
                    } else if (error.response.status === 404 && error.response.data === TENANT_STATUSES_MESSAGE.invalidCredentials) {
                        getCredentials();
                        setTenantStatus(tenantStatuses.invalidTenant);
                        setCanApiURLBeChanged(false);
                        handleFirstTimeUse(tenantStatuses.invalidTenant);
                    } else if (error.response.status !== 401) {
                        toastNotification(error.response.data, 'error');
                    }
                }
            })
    }, []);


    useEffect(() => {
        if (loading) {
            return;
        }

        const element = gzServerAddressRef.current;
        element.addEventListener('change', e => handleGzServerAddressChange(e));

        return () => {
            element.removeEventListener('change', e => handleGzServerAddressChange(e));
        };
    }, [loading]);

    useEffect(() => {
        if (loading) {
            return;
        }

        const element = gzApiKeyRef.current;
        element.addEventListener('change', e => handleGzApiKeyChange(e));

        return () => {
            element.removeEventListener('change', e => handleGzApiKeyChange(e));
        };
    }, [loading]);

    useEffect(() => {
        if (loading) {
            return;
        }

        const element = rmmApiKeyRef.current;
        element.addEventListener('change', e => handleRmmApiKeyChange(e));

        return () => {
            element.removeEventListener('change', e => handleRmmApiKeyChange(e));
        };
    }, [loading]);

    useEffect(() => {
        if (loading) {
            return;
        }

        const element = rmmApiSecretRef.current;
        element.addEventListener('change', e => handleRmmApiSecretChange(e));

        return () => {
            element.removeEventListener('change', e => handleRmmApiSecretChange(e));
        };
    }, [loading]);

    useEffect(() => {
        if (loading) {
            return;
        }

        const element = serverAddressRef.current;
        element.addEventListener('change', e => handleServerAddressChange(e));

        return () => {
            element.removeEventListener('change', e => handleServerAddressChange(e));
        };
    }, [loading]);

    const handleGzAuthorizationToggle = (e) => {
        setIsGzAuthorizationEnabled(e.detail.checked);
    }

    const handleGzCredentialsToggle = (e) => {
        setIsGzAuthorizationEnabled(!e.detail.checked);
    }

    useEffect(() => {
        if (loading) {
            return;
        }

        const gzAuthorizationElement = gzAuthorizationRef.current;
        gzAuthorizationElement.addEventListener('toggleChange', handleGzAuthorizationToggle);

        if (isGzAuthenticationEnabled === false && isGzAuthenticationEnabled === false) {
            gzAuthorizationRef.current.disabled = true;
            gzCredentialsRef.current.disabled = true;
        }

        return () => {
            gzAuthorizationElement.removeEventListener('toggleChange', handleGzAuthorizationToggle);
        };
    }, [loading]);


    useEffect(() => {
        if (loading) {
            return;
        }

        const gzCredentialsElement = gzCredentialsRef.current;
        gzCredentialsElement.addEventListener('toggleChange', handleGzCredentialsToggle);

        return () => {
            gzCredentialsElement.removeEventListener('toggleChange', handleGzCredentialsToggle);
        };
    }, [loading]);

    useEffect(() => {
        if (loading) {
            return;
        }
        gzApiKeyFieldRef.current.disabled = isGzAuthorizationEnabled;
        gzServerAddressFieldRef.current.disabled = isGzAuthorizationEnabled;

        if (gzApiKey === '' && gzServerAddress === '') gzServerAddressFieldRef.current.readonly = false;

        if (isGzAuthorizationEnabled === true) {
            gzApiKeyRef.current.value = initialGzApiKey;
            gzServerAddressRef.current.value = initialGzServerAddress;
            gzApiKeyRef.current.markAsValid();
            gzServerAddressRef.current.markAsValid();
        }

        let items = changedValues;
        items.gzServerAddress = null;
        items.gzApiKey = null;
        setChangedValues(items);
    }, [isGzAuthorizationEnabled]);

    useEventListener('click', e => saveData(e), saveSettingsButtonRef.current);

    return (
        <div style={{ overflowY: 'scroll', maxHeight: '90vh' }}>
            <div className='p-4'>
                <gz-title fontsize="20" fontweight="500" style={{ opacity: '0.7' }}>Integration</gz-title>
                {loading ?
                    <div className='center-div'>
                        <gz-progress-spinner indeterminate="" spinnerwidth="medium"></gz-progress-spinner>
                    </div> :
                    <div className='mt-5'>
                        <div>
                            <gz-title fontsize="18" fontweight="500">GravityZone credentials</gz-title>
                            <div className='p-3 settings-tab-sub-section mt-2 mb-3'>
                                <div className='settings-tab-inline'>
                                    <gz-toggle id="gz-authorization-toggle" gz-form-input="" errortooltiptext="" class='settings-tab-toggle' ref={gzAuthorizationRef} {...(isGzAuthorizationEnabled === true ? { checked: true } : {})}></gz-toggle>
                                    <gz-title fontsize="18" fontweight="500" class="pt-2">GravityZone user token</gz-title>
                                </div>
                                <div className='pb-4'>
                                    <gz-text fontsize="12" lineheight="21">
                                        Use the GravityZone SSO user token for authorization. <br />GravityZone IdP must be turned on under Settings &gt; System &gt; Authentication in order to use this option.
                                    </gz-text>
                                </div>
                                <div>
                                    <div className='d-flex flex-row align-items-center'>
                                        <div class="p-1">
                                            <span className="ds-icon-validation_error" style={{ fontSize: '24px', color: '#ffa438', marginBottom: '10px' }}></span>
                                        </div>
                                        <div className='pb-1'>
                                            <gz-text fontsize="12" lineheight="21" fontweight="600">
                                                Warning:
                                            </gz-text>
                                        </div>
                                    </div>
                                </div>
                                <div className='pb-2'>
                                    <gz-text fontsize="12" lineheight="21">
                                        If you are setting up the integration for the first time, contact the Bitdefender Support team to obtain access to the <b>GravityZone user token</b> and the <b>Sign in with GravityZone</b> features.
                                        <gz-link fontsize="12" href="https://www.bitdefender.com/business/support/en/77211-933458-enabling-sign-in-with-gravityzone-identity-provider-for-first-time-integrators.html" type="quiet" color="primary">
                                            Learn more
                                        </gz-link>
                                    </gz-text>
                                </div>
                                <div className='settings-tab-inline mt-4'>
                                    <gz-toggle id="gz-credentials-toggle" gz-form-input="" errortooltiptext="" class='settings-tab-toggle' ref={gzCredentialsRef}  {...(isGzAuthorizationEnabled === false ? { checked: true } : {})}></gz-toggle>
                                    <gz-title fontsize="18" fontweight="500" class="pt-2">GravityZone API key</gz-title>
                                </div>
                                <div className='pb-2'>
                                    <gz-text fontsize="12" lineheight="21">
                                        Please provide the needed GravityZone details for the integration:
                                    </gz-text>
                                </div>
                                <div className='mt-3 mb-4'>
                                    <gz-form-field label="GravityZone Access URL" id="gzServerAddressField" ref={gzServerAddressFieldRef}
                                        {...(!canApiURLBeChanged ? { readonly: true } : {})}>
                                        <gz-input
                                            gz-form-input
                                            ref={gzServerAddressRef}
                                            type= "text"
                                            errortooltiptext="Invalid GravityZone access URL."
                                            maxchars="100"
                                            value={gzServerAddress}
                                            inputwidth="400"
                                            id='gzServerAddress'
                                            disabled={true}>
                                        </gz-input>
                                    </gz-form-field>
                                    <gz-form-field label="GravityZone API Key" id="gzApiKeyField" ref={gzApiKeyFieldRef}>
                                        <gz-input
                                            gz-form-input
                                            ref={gzApiKeyRef}
                                            type={gzApiKeyInputType}
                                            errortooltiptext="Invalid GravityZone API Key"
                                            maxchars="100"
                                            value={gzApiKey}
                                            inputwidth="400"
                                            id='gzApiKey'>
                                        </gz-input>
                                    </gz-form-field>
                                </div>
                            </div>
                        </div>

                        <div className='my-5'>
                            <gz-title fontsize="18" fontweight="500">Datto RMM Credentials</gz-title>
                            <div className='p-3 settings-tab-sub-section mt-2 mb-3'>
                                <div className='pb-2'>
                                    <gz-text fontsize="12" lineheight="21">
                                        Please provide the needed Datto RMM details for the integration:
                                    </gz-text>
                                </div>
                                <div className='mt-2 mb-3'>
                                    <gz-form-field label="Datto RMM API URL" id="rmmServerAddressField"
                                        {...(!canApiURLBeChanged ? { readonly: true } : {})}>
                                        <gz-input
                                            gz-form-input
                                            ref={serverAddressRef}
                                            type="text"
                                            errortooltiptext="Invalid Datto RMM API URL"
                                            maxchars="100"
                                            value={serverAddress}
                                            inputwidth="400"
                                            id='serverAddress'>
                                        </gz-input>
                                    </gz-form-field>
                                    <gz-form-field label="Datto RMM API Key" id="rmmApiKeyField">
                                        <gz-input
                                            gz-form-input
                                            type="text"
                                            errortooltiptext="Invalid Datto RMM API Key"
                                            maxchars="100"
                                            value={rmmApiKey}
                                            inputwidth="400"
                                            id='rmmApiKey'
                                            ref={rmmApiKeyRef}>
                                        </gz-input>
                                    </gz-form-field>
                                    <gz-form-field label="Datto RMM API Secret Key" id="rmmApiSecretField">
                                        <gz-input
                                            gz-form-input
                                            type="password"
                                            errortooltiptext="Invalid Datto RMM API Secret Key"
                                            maxchars="100"
                                            value={rmmApiSecret}
                                            inputwidth="400"
                                            id='rmmApiSecret'
                                            ref={rmmApiSecretRef}>
                                        </gz-input>
                                    </gz-form-field>
                                </div>
                            </div>
                        </div>
                    </div>}
            </div>

            <div className='page-footer fixed-bottom-navbar'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-sm-3 navbar-light nav-bottom-shadow py-3' style={{ visibility: loading === true ? 'hidden' : 'visible' }}>
                            <gz-button
                                ref={saveSettingsButtonRef}
                                type="full"
                                color="primary"
                                buttonheight="medium"
                                buttonfontsize="medium"
                                id='save-settings-btn'
                                {...(disabledButton === true) ? { disabled: true } : {}}>
                                SAVE CHANGES
                            </gz-button>
                        </div>
                        <div className='col-sm-7 nav-bottom-shadow py-2' >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SettingsTab;
